
import { UPDATE_PREV_NEXT_PUB_ID } from '@/store'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class ArticlePagePrevNext extends Vue {
  get showLinks() {
    return this.previousPublicationId !== undefined || this.nextPublicationId !== undefined
  }

  get previousPublicationId() {
    return this.$store.getters.prevPublicationId
  }

  get nextPublicationId() {
    return this.$store.getters.nextPublicationId
  }

  get previousArticleTitle() {
    return `Previous article: MR${this.previousPublicationId}`
  }

  get nextArticleTitle() {
    return `Next article: MR${this.nextPublicationId}`
  }

  get searchTitle() {
    const { query, pageNumber, pageSize, sortBy, facets } = this.$store.state.PrevNextPublicationsModule.searchParams
    return `Back to searching '${query}'`
  }

  onClickBackToSearch() {
    const { query, pageNumber, pageSize, sortBy, facets } = this.$store.state.PrevNextPublicationsModule.searchParams
    this.$router.push({
      name: 'PublicationsSearch',
      query: {
        query,
        page: pageNumber,
        size: pageSize,
        sort: sortBy,
        facets,
      },
    })
  }

  onClickPreviousHandler() {
    if (this.previousPublicationId !== undefined) {
      this.dispatchUpdateCurrentArticleId(this.previousPublicationId)
    }
  }

  onClickNextHandler() {
    if (this.nextPublicationId !== undefined) {
      this.dispatchUpdateCurrentArticleId(this.nextPublicationId)
    }
  }

  private dispatchUpdateCurrentArticleId(publicationId: number) {
    this.$store.dispatch(UPDATE_PREV_NEXT_PUB_ID, {
      publicationId,
    })
  }
}
