
import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { ArticlePageBibInfoMixin } from './mixins'
import { addLastDot } from '@/utils/utils'
import { Issue } from './types'
import ArticlePageBibInfoCommon from './ArticlePageBibInfoCommon.vue'
import FullBibIssueFormat from '@/components/bib-formatting/FullBibIssueFormat.vue'

@Component({
  components: {
    ArticlePageBibInfoCommon,
    FullBibIssueFormat,
  },
})
export default class ArticlePageBibInfoJournalInfo extends mixins(ArticlePageBibInfoMixin) {
  //
  // COMPUTED PROPERTIES
  //
  get issue() {
    return this.article.issue?.issue
  }

  get translatedIssue() {
    return this.article.issue?.translatedIssue
  }

  get journalLink() {
    if (!this.issue?.journal) return {}

    const journalId = this.issue.journal.jourId

    if (journalId !== null) {
      return { name: 'SerialProfile', query: { journalId } }
    }
    // It's an old entry that doesn't have a journalId. Have to search for it instead.
    return {
      name: 'JournalSearch',
      query: {
        query: `"${this.issue.journal.shortTitle}"`,
      },
    }
  }

  get journalTitle() {
    if (!this.issue?.journal?.title) return ''

    return addLastDot(this.issue.journal.title)
  }

  get journalAbbr() {
    if (!this.issue?.journal?.shortTitle) return ''

    return this.issue.journal.shortTitle
  }

  get isRegular(): boolean {
    return this.determineIsRegular(this.issue)
  }

  get isTranslatedRegular(): boolean {
    return this.determineIsRegular(this.translatedIssue)
  }

  determineIsRegular(issue): boolean {
    if (issue == null) {
      issue = this.issue
    }
    if (issue.elementOrd?.toLowerCase() === 'u') {
      // Unusual. Changes format to "No. IssueNum (Year)""
      return false
    }
    // elementOrd == 'r' or elementOrd doesn't exist
    // If vol and issue are empty, "Year" with no paren
    // Else "Volume (Year), no. IssueNum"
    return true
  }

  get displayYearParen(): boolean {
    return this.determineDisplayYearParent(this.issue)
  }

  get displayYearParenTranslated(): boolean {
    return this.determineDisplayYearParent(this.translatedIssue)
  }

  determineDisplayYearParent(issue): boolean {
    // Don't display paren if elementOrd == regular and volume and issue are empty
    if (issue == null) {
      issue = this.issue
    }
    if (this.determineIsRegular(issue) && issue.volume === null) {
      // MR4795809 - Don't put year in paren if Regular element order and volume is empty
      return false
    }
    if (issue.volume === null && issue.issNum === null && issue.issName === null) {
      return false
    }
    return true
  }

  //
  // METHODS
  //

  publicationQueries(pissue: Issue) {
    if (!pissue) return { year: null, vol: null, iss: null }

    const publication = pissue.journal.jourId === null
      ? `j:"${this.journalAbbr}"`
      : `ji:${pissue.journal.jourId}`

    const issue = `iss:${pissue.issNum}` // Note - there's no issue name search
    const volume = pissue.volume ? `v:${pissue.volume}` : ''
    const year = pissue.pubYearInt ? `y:${pissue.pubYearInt}` : ''

    return {
      year: `${publication} ${year}`.trim(),
      vol: `${publication} ${volume}`.trim(),
      iss: `${publication} ${volume} ${issue}`.trim(),
      exact: `${publication} ${year} ${volume} ${issue}`.trim(),
    }
  }
}
